<!-- eslint-disable -->
<template>
  <div class="page_box">
    <div v-loading="pageLoading">
      <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
      <div class="group_type">
        <div 
          :class="formData.type==item.type?'group_type_item group_type_item_active':'group_type_item'"
          v-for="(item,index) in groupTypeList" 
          :key="index" 
          @click="selectGtypeFn(item.type)">
          <div>{{ item.name }}</div>
          <div>{{ item.context }}</div>
        </div>
      </div>
      <el-form ref="form" :model="formData" :rules="formRules" label-width="160px" :disabled="isDetail">
        <div class="form_box">
          <div class="module_title">
            <div class="title_line"></div>
            <div>活动信息</div>
          </div>
          <el-form-item class="form_item" label="活动名称：" prop="activityName">
            <el-input v-model="formData.activityName"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="活动时间：" prop="startTime">
            <el-date-picker
              v-model="activityDate"
              @change="activityDateChange"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item class="form_item" label="活动状态：" prop="status">
              <el-switch
                v-model="formData.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="2">
              </el-switch>
            </el-form-item>
          <el-form-item class="form_item" label="售卖金额：" prop="price">
            <el-input v-model="formData.price"></el-input> 元
          </el-form-item>
          <el-form-item class="form_item" label="日常价：" prop="referencePrice">
            <el-input v-model="formData.referencePrice"></el-input> 元
          </el-form-item>
          <el-form-item class="form_item" label="立省金额：" prop="discountPrice">
            <el-input v-model="formData.discountPrice"></el-input> 元
          </el-form-item>
          <el-form-item class="form_item" label="活动标签：" prop="labels" style="width: 100%;">
            <el-tag
              size="small"
              :key="tag"
              v-for="tag in formData.labels"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
              {{tag}}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="mini"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="mini" @click="showInput">+ 新增标签</el-button>
          </el-form-item>
          <div class="up_box">
            <el-form-item class="form_item" label="入口图：" prop="icon">
              <file-upload
                v-model="formData.icon"
                :uploadBusinessInfo="{
                  businessType: '0'
                }"
                :defaultFileList="iconList"
                :maxNum="1"
                width="72px"
                height="72px"
                @uploadSuccess="uploadSuccess('icon')"
              ></file-upload>
            </el-form-item>
            <el-form-item class="form_item" label="拼团主图banner：" prop="bannerImage">
              <file-upload
                v-model="formData.bannerImage"
                :uploadBusinessInfo="{
                  businessType: '0'
                }"
                :defaultFileList="bannerImageList"
                :maxNum="1"
                width="72px"
                height="72px"
                @uploadSuccess="uploadSuccess('bannerImage')"
              ></file-upload>
            </el-form-item>
          </div>
          <div class="up_box">
            <el-form-item class="form_item" label="购买流程图：" prop="purchaseImage">
              <file-upload
                v-model="formData.purchaseImage"
                :uploadBusinessInfo="{
                  businessType: '0'
                }"
                :defaultFileList="purchaseImageList"
                :maxNum="1"
                width="72px"
                height="72px"
                @uploadSuccess="uploadSuccess('purchaseImage')"
              ></file-upload>
            </el-form-item>
            <el-form-item class="form_item" label="分享图片：" prop="shareImage">
              <file-upload
                v-model="formData.shareImage"
                :uploadBusinessInfo="{
                  businessType: '0'
                }"
                :defaultFileList="shareImageList"
                :maxNum="1"
                width="72px"
                height="72px"
                @uploadSuccess="uploadSuccess('shareImage')"
              ></file-upload>
            </el-form-item>
          </div>
          <el-form-item class="form_item" label="拼团详情：" prop="detailContent" style="width: 100%;">
            <custom-editor :option="option" v-model="formData.detailContent"></custom-editor>
          </el-form-item>
        </div>
        <div class="form_box">
          <div class="module_title">
            <div class="title_line"></div>
            <div>活动规则</div>
          </div>
          <el-form-item class="form_item" label="成团人数：" prop="groupNum">
            <el-input v-model="formData.groupNum"></el-input> 人
          </el-form-item>
          <el-form-item class="form_item" label="成团有效期：" prop="groupValidTime">
            <el-input v-model="formData.groupValidTime"></el-input> 小时
          </el-form-item>
          <el-form-item class="form_item" label="是否开启自动成团：" prop="hasRobot">
            <el-radio-group v-model="formData.hasRobot">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="formData.hasRobot == 1" class="form_item" label="" prop="robotDelayTime" style="width: 100%;">
            拼单后 <el-input v-model="formData.robotDelayTime" style="width: 100px;"></el-input> 小时开启
          </el-form-item>

          <el-form-item class="form_item" label="自动创建模拟团：" prop="autoCreateTeam">
            <el-radio-group v-model="formData.autoCreateTeam">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>


          <el-form-item class="form_item" label="" v-if="formData.autoCreateTeam == 1" prop="mixTeamCount">
            最小拼团个数：<el-input v-model="formData.mixTeamCount" style="width: 100px;"></el-input>
          </el-form-item>

          <el-form-item class="form_item" label="" v-if="formData.autoCreateTeam == 1" prop="autoCreatTeamCount">
            自动创建个数：<el-input v-model="formData.autoCreatTeamCount" style="width: 100px;"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="" v-if="formData.autoCreateTeam == 1">
            单个团机器人个数：
            <el-radio-group v-model="formData.autoTeamRobot">
              <el-radio :label="2">固定值</el-radio>
              <el-radio :label="1">随机数</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item class="form_item" label="" prop="autoTeamRobotCount" v-if="formData.autoTeamRobot == 2">
            <el-input v-model="formData.autoTeamRobotCount" style="width: 100px;"></el-input>
          </el-form-item>



        </div>
        <div class="form_box">
          <div class="module_title">
            <div class="title_line"></div>
            <div>选择卡</div>
          </div>
          <div>
            <el-button class="select_dialog" @click="selectCardFn" type="text">选择套餐卡</el-button>
            <el-table
              :data="tableDataAll.tableCardData"
              border
              size="mini"
              style="width: 100%"
              :header-cell-style="{
                borderColor: '#EBEEF5',
                background: '#FAFAFA',
                color: '#333',
                fontWeight: 'normal'
              }">
              <el-table-column prop="cardTemplateName" label="卡模版名称" min-width="180"></el-table-column>
              <el-table-column prop="" label="有效期限（天）" min-width="180">
                <template slot-scope="scope">
                  <div>{{ scope.row.validPeriod == -1?'永久': (scope.row.validPeriod == -2 ? '截至到 '+scope.row.validTimeEnd : scope.row.validPeriod + '天')  }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="cardAmount" label="金额（元）" min-width="180"></el-table-column>
              <el-table-column prop="carryingAmount" label="账面金额（元）" min-width="180"></el-table-column>
              <el-table-column prop="address" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button type="text" @click="deleteTableFn('card',scope.$index)" size="mini">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form>
    </div>
    <div class="form_btn" v-if="!isDetail">
      <el-button @click="cancelFn">取消</el-button>
      <el-button type="primary" @click="saveForm">保存</el-button>
    </div>
    
    <!-- 卡模版弹窗 -->
    <el-dialog class="dialog_box" title="选择卡模版" :visible.sync="dialogCardVisible" width="80%" :before-close="cardClose" :close-on-click-modal="false">
      <div class="search_store">
        <div>卡模版名称：</div>
        <el-input placeholder="请输入内容" v-model="dialogSearchForm.cardTemplateName" class="input-with-select">
          <el-button @click="getCardList('input')" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <el-table
        ref="dialogCardTable"
        v-loading="dialogLoading"
        :data="originalCardList"
        @select="selCardChangeFn"
        border
        size="mini"
        style="width: 100%"
        max-height="410"
        :header-cell-style="{
          borderColor: '#EBEEF5',
          background: '#FAFAFA',
          color: '#333',
          fontWeight: 'normal'
        }">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="cardTemplateName" label="卡模版名称" min-width="180"></el-table-column>
        <el-table-column prop="cardAmount" label="开卡金额（元）" min-width="180"></el-table-column>
        <el-table-column prop="carryingAmount" label="账面金额（元）" min-width="180"></el-table-column>
        <el-table-column prop="" label="有效期限" min-width="180">
          <template slot-scope="scope">
            <div>{{ scope.row.validPeriod == -1?'永久': (scope.row.validPeriod == -2 ? '截至到 '+scope.row.validTimeEnd : scope.row.validPeriod + '天')  }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page_pagination"
        @size-change="handleSizeCardChange"
        @current-change="handleCurrentCardChange"
        :current-page="pageData.num"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total">
      </el-pagination>
      <div class="dialog_btn">
        <el-button @click="cardConfirm(false)" size="mini">取消</el-button>
        <el-button @click="cardConfirm(true)" type="primary" size="mini">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");

import { emptyToLine, splitThousands } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";
// 价格验证方法
const checkIsPositive =(rule, value, callback)=> {
  if(!value){
    callback()
  } else {
    const reg = /^\d+(?=\.{0,1}\d+$|$)/
    if (reg.test(value)){
      callback()
    } else {
      callback(new Error('价格需大于等于0'))
    }
  }
}
export default {
  // emptyToLine = emptyToLine;
  // splitThousands = splitThousands;
  //import引入的组件需要注入到对象中才能使用
  // @marketing.Action queryCardTemplateList;
  // @management.Action getStoreList
  // @management.Action getStoreCouponList

  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        isDetail: false,//是否为详情
        pageName: null, //页面名称
        formData: {
          type: 1,                            //拼团类型 1-普通 2-老带新
          activityName: '',                   //活动名称
          startTime: '',                      //活动开始时间
          endTime: '',                        //活动结束时间
          status: 1,                          //拼团状态 1-启用 2-不启用
          price: '',                          //售卖金额
          referencePrice: '',                 //日常价
          discountPrice: '',                  //立省金额
          labels: [],                         //活动标签
          groupNum: '',                       //成团人数
          groupValidTime: '',                 //成团有效期时间
          hasRobot: 0,                        //是否自动成团 1-是 0-否
          robotDelayTime: '',                 //自动成团开启时间

          icon: '',                           //入口图
          bannerImage: '',                    //拼团主图banner
          purchaseImage: '',                  //购买流程图
          shareImage: '',                     //分享图片
          detailContent: '',                 //拼团详情
          radio: 1,                           //是否开启自动成团

          autoCreateTeam: 0,                  // 是否开启自动创建虚拟团 1开启 0不开启
          mixTeamCount: '',  //最小拼团个数
          autoCreatTeamCount: '', //自动创建拼团个数
          autoTeamRobot: 1, //单个自动创建的拼团的机器人个数 1-随机值,2-固定值
          autoTeamRobotCount: '', //单个拼团机器人固定值个数
        },
        formRules: {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
          ],                   //活动名称
          startTime: [
            { required: true, message: '请选择活动时间', trigger: 'change' },
          ],                      //活动开始时间
          endTime: [
            { required: true, message: '请选择活动时间', trigger: 'change' },
          ],                        //活动结束时间
          price: [
            { required: true, message: '请输入售卖金额', trigger: 'blur' },
            { validator: checkIsPositive }
          ],                          //售卖金额
          referencePrice: [
            { required: true, message: '请输入日常价', trigger: 'blur' },
            { validator: checkIsPositive }
          ],                 //日常价
          discountPrice: [
            { required: true, message: '请输入立省金额', trigger: 'blur' },
            { validator: checkIsPositive }
          ],                  //立省金额
          labels: [
            { required: true, message: '请添加活动标签', trigger: 'blur' },
          ],                         //活动标签
          groupNum: [
            { required: true, message: '请设置成团人数', trigger: 'blur' },
          ],                         //成团人数
          groupValidTime: [
            { required: true, message: '请设置成团有效时长', trigger: 'blur' },
          ],                   //成团有效期时间
          robotDelayTime: [
            { required: true, message: '请设置成团开启时长', trigger: 'blur' },
          ],                   //自动成团开启时间

          icon: [
            { required: true, message: '请上传入口图', trigger: 'blur' },
          ],                           //入口图
          bannerImage: [
            { required: true, message: '请上传拼团主图banner', trigger: 'blur' },
          ],                    //拼团主图banner
          purchaseImage: [
            { required: true, message: '请上传流程图', trigger: 'blur' },
          ],                  //购买流程图
          shareImage: [
            { required: true, message: '请上传分享图片', trigger: 'blur' },
          ],                     //分享图片
          detailContent: [
            { required: true, message: '请添加拼团详情', trigger: 'blur' },
          ],                   //分享图片
          mixTeamCount: [
            { required: true, message: '请输入最小拼团个数', trigger: 'blur' },
          ],              //分享图片
          autoCreatTeamCount: [
            { required: true, message: '请输入自动创建拼团个数', trigger: 'blur' },
          ],              //分享图片
          autoTeamRobotCount: [
            { required: true, message: '请输入机器人个数', trigger: 'blur' },
          ],
                           //拼团详情
          subType: [
            { required: true, message: '请输入子坑位', trigger: 'blur' },
          ],
          channel: [
            { required: true, message: '请选择渠道', trigger: 'change' },
          ],
          type: [
            { required: true, message: '请选择页面', trigger: 'change' },
          ],
          parentNo: [
            { required: true, message: '请选择坑位', trigger: 'change' },
          ]
        },
        groupTypeList: [         //拼团类型列表
          { name: '普通拼团', type: 1, context: '用户下单后邀请好友拼团' },
          { name: '老带新拼团', type: 2, context: '新、老用户都可开团，只有新用户可以参团' }
        ],
        activityDate: [],      //活动时间
        iconList: [],
        bannerImageList: [],
        purchaseImageList: [],
        shareImageList: [],
        option: {
          height: 500
        },
        inputVisible: false,
        inputValue: '',
        tableDataAll: {
          tableCardData: [],                        //已选卡模版
        },

        selectCardData: [],                         //选择的卡数据

        pageData: {                                 //分页数据
          num: 1,
          size: 10,
          total: 0
        },
        pageLoading: false,                         //页面loading
        dialogLoading: false,                       //弹窗加载
        dialogCardVisible: false,                    //卡模版弹窗
        originalCardList: [],                     //原卡模版列表
        dialogSearchForm: {
          storeCode: '',
          cardTemplateName: ''
        },                       //弹窗筛选数据
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "拼团活动", path: "/management/groupBuying/groupActivity/list" },
        {
          name: this.pageName
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 拼团类型选择
    selectGtypeFn(type){
      this.formData.type = type;
    },

    // 活动时间选择
    activityDateChange(e){
      this.formData.startTime = e[0];
      this.formData.endTime = e[1];
    },
    
    // 标签删除
    handleClose(tag) {
      this.formData.labels.splice(this.formData.labels.indexOf(tag), 1);
    },
    // 新增标签展示input
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 新增标签确认
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.formData.labels.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },

    // 图片上传成功
    uploadSuccess(e){
      this.$refs.form.clearValidate(e)
    },


    // 卡模版，优惠券反显
    backDisplayFn(type){
      if(type == 'card'){
        let selectCardData = this.selectCardData;
        let originalCardList = this.originalCardList;
        if(selectCardData.length>0){
          selectCardData.forEach(item => {
            originalCardList.forEach(ele => {
              if(item.cardTemplateCode == ele.cardTemplateCode){
                this.$refs.dialogCardTable.toggleRowSelection(ele);
              }
            })
          })
        }
      }
      if(type == 'coupon'){
        let selectCouponData = this.selectCouponData;
        let originalCouponList = this.originalCouponList;
        if(selectCouponData.length>0){
          selectCouponData.forEach(item => {
            originalCouponList.forEach(ele => {
              if(item.activityCode == ele.activityCode){
                this.$refs.dialogCouponTable.toggleRowSelection(ele);
              }
            })
          })
        }
      }
    },

    // 选择卡模版
    selectCardFn(){
      this.pageData = {                                 //分页数据
        num: 1,
        size: 10,
        total: 0
      },
      this.getCardList().then(res =>{
        this.selectCardData = JSON.parse(JSON.stringify(this.tableDataAll.tableCardData))
        this.backDisplayFn('card')
      });
     
      this.dialogCardVisible = true;
    },
    // 卡模版翻页
    handleCurrentCardChange(e){
      this.pageData.num = e;
      this.getCardList().then(res =>{
        this.backDisplayFn('card')
      });
     
    },
    // 卡模版选择每页条数
    handleSizeCardChange(e){
      this.pageData.size = e;
      this.getCardList().then(res =>{
        this.backDisplayFn('card')
      });
     
    },
    // 卡模版弹窗按钮操作
    cardConfirm(type){
      if(type){
        if(this.selectCardData.length>0){
          this.tableDataAll.tableCardData = JSON.parse(JSON.stringify(this.selectCardData));
          this.dialogCardVisible = false;
        }else{
          this.$message.warning('请选择套餐卡')
        }
      }else{
        this.selectCardData = [];
        this.dialogCardVisible = false;
      }
    },
    // 卡模版弹窗关闭按钮
    cardClose(done){
      done();
      this.selectCardData = [];
    },
    // 卡模版弹窗列表checked change
    selCardChangeFn(rows, row){
      let selectCardData = this.selectCardData;       //确认选择前暂存数据字段
      let isChecked = rows.some(item => item.cardTemplateCode == row.cardTemplateCode); //判断是选中还是取消
      if(isChecked){
        selectCardData.push(row);
      }else{
        this.selectCardData = selectCardData.filter(item => item.cardTemplateCode != row.cardTemplateCode);  //去掉取消选中的数据
      }
    },
    // 已选列表删除
    deleteTableFn(type, index){
      if(type == 'card'){
        this.$confirm('是否删除此卡?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableDataAll.tableCardData.splice(index,1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
      if(type == 'coupon'){
        this.$confirm('是否删除此优惠券?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableDataAll.tableCouponData.splice(index,1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
      console.log(index)
    },

    // 表单保存
    saveForm(){
      // return
      this.$refs.form.validate((valid) => {
        if (valid) {
          if(this.tableDataAll.tableCardData.length<1){
            this.$message.warning('请选择套餐卡')
            return
          }
          if(this.isNext) return;
          this.isNext = true;
          this.pageLoading = true;
          this.$store.dispatch(`management/groupActivityAdd`,{
            ...this.formData,
            groupActivityId: this.$route.query.groupActivityId||'',
            cardTemplateList: this.tableDataAll.tableCardData
          }).then(res => {
            this.$message.success('保存成功！')
            this.$router.back()
            this.isNext = false;
            this.pageLoading = false;
          }).catch(err => {
            this.isNext = false;
            this.pageLoading = false;
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelFn(){
      this.$router.back();
    },

    getDetail(groupActivityId){
      this.$store.dispatch('management/groupActivityDetail',{
        groupActivityId
      }).then(res => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = res.data[key] || '';
        })
        this.tableDataAll.tableCardData = res.data.cardTemplateList;
        this.iconList = [{url: res.data.icon}];
        this.bannerImageList = [{url: res.data.bannerImage}];
        this.purchaseImageList = [{url: res.data.purchaseImage}];
        this.shareImageList = [{url: res.data.shareImage}];
        this.activityDate = [ res.data.startTime, res.data.endTime ];
      })
    },

    // 获取卡模版列表
    async getCardList(type=''){
      if(type == 'input'){
        this.pageData = {                                 //分页数据
          num: 1,
          size: 10,
          total: 0
        }
      }
      this.dialogLoading = true;
      await this.$store.dispatch('marketing/queryCardTemplateList',{
        pageNum: this.pageData.num,
        pageSize: this.pageData.size,
        cardTemplateName: this.dialogSearchForm.cardTemplateName,
        enableStatus: 1,
      }).then(res => {
        this.originalCardList = res.data.list || [];
        this.pageData.total = res.data.total;
        this.dialogLoading = false;
        if(type == 'input'){
          setTimeout(() => {
            this.backDisplayFn('card')
          }, 0);
        }
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 获取当前路由的名称
    this.isDetail = this.$route.name === "groupActivityDetail";
    this.pageName = this.$route.meta.title;
    if (this.$route.query.groupActivityId) {
      this.getDetail(this.$route.query.groupActivityId);
    }

  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    console.log('From:', from);
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;
    .form_box{
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      margin-top: 20px;
      .module_title{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #262626;
        margin-bottom: 20px;
        .title_line{
          width: 4px;
          height: 20px;
          background: #409EFF;
          margin-right: 5px;
          border-radius: 1px;
        }
      }
      .form_item{
        width: 40%;
        flex-shrink: 0;
        box-sizing: border-box;
        .el-input{
          width: 80%;
        }
      }
      .form_item_price{
        display: flex;
        justify-content: start;
        .el-input{
          width: 100px;
        }
        span{
          display: inline-block;
          margin-left: 8px;
        }
      }
      .up_box{
        display: flex;
        align-items: center;
      }
    }
    .form_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding-bottom: 20px;
    }
  }
  ::v-deep .form_item {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    // input[type="number"] {
    //   -moz-appearance: textfield;
    // }
    inpit {
      border: none
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
	  display: none;
	}

  .dialog_box{
    .search_store{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-input{
        width: 300px;
      }
    }
    .page_pagination{
      text-align: center;
      margin-top: 20px;
    }
    .dialog_btn{
      text-align: end;
    }
  }
  .table_form{
    display: flex;
    align-items: center;
  }
  .group_type{
    display: flex;
    align-items: center;
    .group_type_item{
      background: #fff;
      text-align: center;
      width: 200px;
      height: 100px;
      margin-right: 20px;
      margin-top: 20px;
      padding: 0 30px;
      box-sizing: border-box;
      border: 2px solid #fff;
      cursor: pointer;
      div:nth-child(1){
        font-weight: bold;
        font-size: 16px;
        margin-top: 20px;
      }
      div:nth-child(2){
        font-size: 12px;
        color: #666;
        margin-top: 6px;
      }
    }
    .group_type_item_active{
      border: 2px solid #409EFF;
      color: #409EFF;
    }
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 24px;
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px !important;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .dialog_box{
    .search_store{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-input{
        width: 300px;
      }
    }
    .page_pagination{
      text-align: center;
      margin-top: 20px;
    }
    .dialog_btn{
      text-align: end;
    }
  }
</style>
